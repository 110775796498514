// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/light/classique/chevron-green.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".header-sub{background-color:#f0fdfe}.header-sub .global-container.header-sub-menu{align-items:unset;width:1600px;max-width:100%;padding:0 15px 0 15px}.header-sub .global-container.header-sub-menu .title-menu{font-size:16px;margin-bottom:20px;border-bottom:1px solid #06df86;color:#475467;line-height:2.2;font-family:MontserratRegular,sans-serif}@media(min-width:1440px){.header-sub .global-container.header-sub-menu .title-menu{font-size:20px}}.header-sub .global-container.header-sub-menu ul{padding-left:0}.header-sub .global-container.header-sub-menu ul .sub-nav-item{position:relative;list-style:none;color:#fff;overflow:hidden;cursor:pointer;font-family:MontserratRegular,sans-serif;font-size:16px}.header-sub .global-container.header-sub-menu ul .sub-nav-item a.article-container{display:flex!important;align-items:center}.header-sub .global-container.header-sub-menu ul .sub-nav-item a{text-decoration:none;cursor:pointer;color:#475467!important}.header-sub .global-container.header-sub-menu ul .sub-nav-item a:hover{color:#06df86!important}.header-sub .global-container.header-sub-menu ul .sub-nav-item a:before{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");height:30px;width:20px;background-size:10px;transform:scale(.5);display:inline-block;vertical-align:middle;margin-right:15px}.header-sub .global-container.header-sub-menu .link-rt{margin-top:65px;text-transform:uppercase}.header-sub .global-container.header-sub-menu .link-rt .sub-nav-item{margin-top:20px;margin-bottom:20px}.header-sub .global-container.header-sub-menu .link-rt .sub-nav-item a{font-family:MontserratRegular,sans-serif;font-weight:700}.top-articles{display:flex;margin-bottom:5px;align-items:center;gap:10px}@media(max-width:1200px){.top-articles{margin-bottom:15px}}.top-articles .article-preview{position:absolute;right:0;top:0;width:50%}.top-articles .article-preview img{width:500px;height:280px;-o-object-fit:cover;object-fit:cover;border-radius:20px;cursor:default}", ""]);
// Exports
module.exports = exports;
