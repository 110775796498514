<template>
  <div class="header-sub">
    <div class="global-container header-sub-menu col-lg-12">
      <div class="row">
        <div class="col-lg-3">
          <h2 class="title-menu">NOS SERVICES</h2>
          <ul>
            <li
              v-for="service in services"
              :key="service.title"
              class="sub-nav-item"
            >
              <a :href="partner !== null && partner === 'matmut' ? service.link : validateUrl(service.link)">
                <span class="menu-label">
                  {{ service.title }}
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-lg-3">
          <h2 class="title-menu">PARRAINAGE</h2>
          <ul>
            <li class="sub-nav-item">
              <a :href="validateUrl('/nos-services/parrainage-vendeur')">
                <span class="menu-label">
                  Parrainer un ami
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-lg-3">
          <h2 class="title-menu">INSPECTEUR</h2>
          <ul>
            <li class="sub-nav-item">
              <a :href="validateUrl('/nos-services/devenir-inspecteur')">
                <span class="menu-label">
                  Devenir inspecteur
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UtilMixin from "../../../mixins/UtilMixin";
export default {
  name: "SubMenuBoard",
  mixins: [UtilMixin],
  data() {
    return {
      route: "",
      partner: null,
      services: {
        credit: {
          title: "Crédit auto",
          link: "/nos-services/credit-auto",
        },
        insurance: {
           title: 'Assurance',
           link: "/nos-services/assurance-auto",
        },
        warranty: {
          title: "Garantie",
          link: "/nos-services/la-garantie-mecanique",
        },
        delivery: {
           title: "Livraison",
           link: "/nos-services/la-livraison-avec-carizy",
        },
        grayCard: {
           title: "Carte Grise & Immatriculation",
          link: "/nos-services/carte-grise-et-assurance",
        },
        moovizy: {
          title: "Moovizy",
          link: "/nos-services/moovizy",
        }
      },
    };
  },
  mounted() {
    // Partnaire loading
    this.route = this.getPartenaire().route;
    this.partner = this.getPartenaire().subdomain;

    if (this.partner) {
      switch (this.partner) {
        case 'matmut':
          this.services.credit.link = 'https://www.matmut.fr/epargne-credit/credit-auto'
          this.services.insurance.link = 'https://www.matmut.fr/app/devisVehiculeinternet/devis/auto/etapeVotreVehicule#xtor=AL-237-%5B%5D-%5Bcarizy_matmut%5D-%5Bparcours_reservation%5D-%5Bdevis_auto%5D'
          break
        case 'macif':
          this.services.credit.link = 'https://www.macif.fr/assurance/particuliers/banque-credit-epargne/credit-automoto?cid=par_Carizy_Credit_TarificationAuto'
          this.services.insurance.link = 'https://www.macif.fr/sites/maciffr/assurance/particuliers/assurance-auto-moto-scooter/assurance-automobile/devis-automobile-en-ligne/app/vehicule/marque'
          break
        case 'groupama':
          this.services.insurance.link = 'https://www.groupama.fr/devis-auto/?xtor=PART&lm=part---Carizy-1Auto---'
          break
      }
    }
  },
  methods: {
    validateUrl(url) {
      switch (this.partner) {
        case 'matmut':
          return url;
          break
        case 'macif':
          return url;
          break
        case 'groupama':
          return url;
          break
        default:
          return url + this.route;
      }
    },
  },
};
</script>
<style lang="scss">
@import "../../../assets/sass/modules/_subMenu";
</style>
